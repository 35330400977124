<template>
    <b-card class="notification-details-card">
        <b-card-header
            class="notification-details-card__header d-flex justify-content-between position-relative"
            header-class="p-0 mb-2"
        >
            <div class="notification-details-card__title-container d-flex">
                <unicon name="bell" width="20" height="20" />
                <h4 class="notification-details-card__title ml-1 mb-0">
                    تفاصيل الإشعار
                </h4>
            </div>
            <div
                class="notification-details-card__notification-status-container d-flex align-items-center"
            >
                <div
                    class="notification-details-card__notification-status-title d-flex"
                >
                    حالة الطلب:
                </div>
                <StatusBadge
                    class="notification-details-card__notification-order-status ml-1"
                    :statusList="orderStatusList"
                    :selectedStatusNumber="notificationDto.dashResponse"
                />
            </div>
        </b-card-header>

        <div class="card-title-seperator" />

        <b-form class="mt-1">
            <b-row>
                <b-col md="6">
                    <b-row>
                        <b-col md="12">
                            <ek-input-text
                                :disabled="true"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'اسم الفعالية',
                                    },
                                ]"
                                name="اسم الفعالية"
                                placeholder="اختر اسم الفعالية "
                                label="اسم الفعالية"
                                v-model="notificationDto.senderName"
                            >
                            </ek-input-text>
                        </b-col>
                        <b-col md="12">
                            <ek-input-text
                                readonly
                                :disabled="true"
                                label="النشاط التجاري"
                                name="CommercialActivity"
                                :value="notificationDto.comertaialAcitvityName"
                            />
                        </b-col>
                        <b-col md="12">
                            <div class="d-flex mt-2 mb-1">
                                <b-form-checkbox
                                    id="checkbox-1"
                                    :disabled="true"
                                    v-model="notificationDto.isForFollowers"
                                    :options="option"
                                >
                                    للمتابعين فقط
                                </b-form-checkbox>
                                <b-form-checkbox
                                    :disabled="true"
                                    id="checkbox-2"
                                    v-model="notificationDto.isForVisitors"
                                    name="checkbox-1"
                                    class="ml-1"
                                >
                                    للزوار فقط
                                </b-form-checkbox>
                            </div>
                        </b-col>

                        <b-col md="12">
                            <ek-input-text
                                :disabled="true"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'عدد الزيارات  مطلوب',
                                    },
                                ]"
                                label="عدد الزيارات"
                                name="عدد الزيارات"
                                v-model="notificationDto.visitorTimes"
                            />
                        </b-col>
                    </b-row>
                </b-col>

                <b-col md="6">
                    <ek-input-text
                        :disabled="true"
                        :rules="[
                            {
                                type: 'required',
                                message: 'عنوان الإشعار مطلوب',
                            },
                        ]"
                        placeholder="أدخل عنوان الإشعار "
                        label="عنوان الإشعار"
                        v-model="notificationDto.title"
                        name="عنوان الاشعار"
                    >
                    </ek-input-text>

                    <ek-input-textarea
                        class="notification-details-card__notification-title-input"
                        :disabled="true"
                        :rules="[
                            { type: 'required', message: 'نص الإشعار مطلوب' },
                        ]"
                        placeholder="أدخل نص الإشعار"
                        label="نص الإشعار"
                        name="نص الإشعار"
                        v-model="notificationDto.body"
                    >
                    </ek-input-textarea>
                </b-col>
            </b-row>
        </b-form>

        <template #footer>
            <slot name="actions" v-bind="notificationDto"> </slot>
        </template>
    </b-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import StatusBadge from "@global/components/StatusBadge.vue";

export default {
    data() {
        return {
            option: [
                { item: "visitor", name: "الزوار فقط" },
                { item: "follow", name: "المتابعين فقط" },
            ],
        };
    },
    computed: {
        ...mapState({
            notificationDto: (state) => state.notifications.notificationDto,
            orderStatusList: (state) => state.orders.orderStatusList,
        }),

    },
    methods: {
        ...mapActions(["fetchNotificationOrderById"])
    },
    props: {
        id: String,
        userId: String
    },
    created() {
        this.fetchNotificationOrderById(this.id);
    },
    components: {
        StatusBadge,
    },
};
</script>

<style lang="scss" scoped>
.notification-details-card {
    &__notification-title-input {
        &::v-deep {
            textarea {
                height: 180px;
                padding-bottom: 3.2rem !important;
            }
        }
    }
}
</style>
