var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"notification-details-card",scopedSlots:_vm._u([{key:"footer",fn:function(){return [_vm._t("actions",null,null,_vm.notificationDto)]},proxy:true}],null,true)},[_c('b-card-header',{staticClass:"notification-details-card__header d-flex justify-content-between position-relative",attrs:{"header-class":"p-0 mb-2"}},[_c('div',{staticClass:"notification-details-card__title-container d-flex"},[_c('unicon',{attrs:{"name":"bell","width":"20","height":"20"}}),_c('h4',{staticClass:"notification-details-card__title ml-1 mb-0"},[_vm._v(" تفاصيل الإشعار ")])],1),_c('div',{staticClass:"notification-details-card__notification-status-container d-flex align-items-center"},[_c('div',{staticClass:"notification-details-card__notification-status-title d-flex"},[_vm._v(" حالة الطلب: ")]),_c('StatusBadge',{staticClass:"notification-details-card__notification-order-status ml-1",attrs:{"statusList":_vm.orderStatusList,"selectedStatusNumber":_vm.notificationDto.dashResponse}})],1)]),_c('div',{staticClass:"card-title-seperator"}),_c('b-form',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('ek-input-text',{attrs:{"disabled":true,"rules":[
                                {
                                    type: 'required',
                                    message: 'اسم الفعالية',
                                } ],"name":"اسم الفعالية","placeholder":"اختر اسم الفعالية ","label":"اسم الفعالية"},model:{value:(_vm.notificationDto.senderName),callback:function ($$v) {_vm.$set(_vm.notificationDto, "senderName", $$v)},expression:"notificationDto.senderName"}})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ek-input-text',{attrs:{"readonly":"","disabled":true,"label":"النشاط التجاري","name":"CommercialActivity","value":_vm.notificationDto.comertaialAcitvityName}})],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex mt-2 mb-1"},[_c('b-form-checkbox',{attrs:{"id":"checkbox-1","disabled":true,"options":_vm.option},model:{value:(_vm.notificationDto.isForFollowers),callback:function ($$v) {_vm.$set(_vm.notificationDto, "isForFollowers", $$v)},expression:"notificationDto.isForFollowers"}},[_vm._v(" للمتابعين فقط ")]),_c('b-form-checkbox',{staticClass:"ml-1",attrs:{"disabled":true,"id":"checkbox-2","name":"checkbox-1"},model:{value:(_vm.notificationDto.isForVisitors),callback:function ($$v) {_vm.$set(_vm.notificationDto, "isForVisitors", $$v)},expression:"notificationDto.isForVisitors"}},[_vm._v(" للزوار فقط ")])],1)]),_c('b-col',{attrs:{"md":"12"}},[_c('ek-input-text',{attrs:{"disabled":true,"rules":[
                                {
                                    type: 'required',
                                    message: 'عدد الزيارات  مطلوب',
                                } ],"label":"عدد الزيارات","name":"عدد الزيارات"},model:{value:(_vm.notificationDto.visitorTimes),callback:function ($$v) {_vm.$set(_vm.notificationDto, "visitorTimes", $$v)},expression:"notificationDto.visitorTimes"}})],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('ek-input-text',{attrs:{"disabled":true,"rules":[
                        {
                            type: 'required',
                            message: 'عنوان الإشعار مطلوب',
                        } ],"placeholder":"أدخل عنوان الإشعار ","label":"عنوان الإشعار","name":"عنوان الاشعار"},model:{value:(_vm.notificationDto.title),callback:function ($$v) {_vm.$set(_vm.notificationDto, "title", $$v)},expression:"notificationDto.title"}}),_c('ek-input-textarea',{staticClass:"notification-details-card__notification-title-input",attrs:{"disabled":true,"rules":[
                        { type: 'required', message: 'نص الإشعار مطلوب' } ],"placeholder":"أدخل نص الإشعار","label":"نص الإشعار","name":"نص الإشعار"},model:{value:(_vm.notificationDto.body),callback:function ($$v) {_vm.$set(_vm.notificationDto, "body", $$v)},expression:"notificationDto.body"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }